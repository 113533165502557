
import { getPrice, Price, register } from "@/service/cns-register";
import { ResolveFailureAnswer, resolveName, StandardResolveAnswer } from "@/service/resolveName";
import { copy } from "@/service/utility";
import { Options, Vue } from "vue-class-component";
import Ranking from "./Ranking.vue";
import { Pawket } from "pawket-js-sdk";
import { getRoyaltyAddress, getRoyaltyEntity, RoyaltyEntity } from "@/service/royalty";

@Options({
  components: {
    Ranking,
  },
})
export default class Search extends Vue {
  public name = "";
  public address = "";
  public resolveAns: StandardResolveAnswer | ResolveFailureAnswer | null = null;
  public isResolving = false;
  public showDetail = false;
  public errorMsg = "";
  public showModal = false;
  public period = 1;
  public price: Price = { name: "", price: -1, annualFee: -1, regYear: -1, royaltyPercentage: -1, registrationFee: -1 };
  public registerErrMsg = "";
  public offer = "";
  public registering = false;
  public regYear = 1;
  public showFill = false;
  public showRanking = false;
  public showRoyalty = false;
  public minLength = 6;

  get isTestnet(): boolean {
    return window.location.host != process.env.VUE_APP_MAINNET_HOST;
  }

  get unit(): string {
    return this.isTestnet ? "TXCH" : "XCH";
  }

  get renew(): boolean {
    return this.resolveAns?.status == "Found";
  }

  get cnsName(): string {
    return `${this.name.toLocaleLowerCase()}.xch`;
  }

  get cnsUrl(): string {
    return `https://${this.cnsName}.cool`;
  }

  get referCode(): string {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const code = params.get("code");
    return code ?? "";
  }

  get royaltyAddress(): string {
    return getRoyaltyAddress(this.referCode);
  }

  get royaltyEntity(): RoyaltyEntity | undefined {
    return getRoyaltyEntity(this.referCode);
  }

  async search(): Promise<void> {
    this.isResolving = true;
    this.name = this.name.replace(/\s/g, "");
    this.name = this.name.split(".")[0];
    this.resolveAns = await resolveName(`${this.name}.xch`);
    if (this.resolveAns.status != "Failure") {
      if (this.resolveAns.status == "NotFound") await this.getPrice();
    }
    this.showDetail = true;
    this.isResolving = false;
  }

  async getPrice(): Promise<void> {
    if (this.regYear > 99) this.regYear = 99;
    if (this.regYear < 1) this.regYear = 1;
    this.price = await getPrice(`${this.name}.xch`, this.regYear, this.renew);
  }

  async register(): Promise<void> {
    this.registering = true;

    const res = await register(`${this.name}.xch`, this.regYear, this.renew, this.royaltyAddress, this.address);
    if (res?.success) {
      this.offer = res.offer ?? "";
      this.address = "";
    } else {
      this.$notify({
        title: "Register Failed",
        text: res?.reason ?? "Unknown Error",
        type: "error",
        duration: 5000,
      });
    }
    this.registering = false;
  }

  async openPawket(): Promise<void> {
    const baseUrl = "https://pawket.app/";
    const client = new Pawket({ baseUrl });
    try {
      const msg = await client.takeOffer(this.offer);
      console.log(msg);
    } catch (error) {
      console.warn(error);
    }
  }

  get isGobyExist(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const chia = (window as any).chia;
    return !!chia;
  }

  openGoby(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const chia = (window as any).chia;
    if (chia) chia.request({ method: "takeOffer", params: { offer: this.offer } });
  }

  reset(): void {
    this.resolveAns = null;
    this.price = { name: "", price: -1, annualFee: -1, regYear: -1, royaltyPercentage: -1, registrationFee: -1 };
    this.errorMsg = "";
  }

  clear(): void {
    this.showDetail = false;
    this.name = "";
    this.resolveAns = null;
    this.errorMsg = "";
    this.registerErrMsg = "";
    this.address = "";
    this.offer = "";
  }

  copy(copyText: string): void {
    copy(copyText);
  }

  get offerUri(): string {
    const dataPrefix = "data:text/txt;charset=utf-8";
    const content = `${dataPrefix},${this.offer}`;
    return encodeURI(content);
  }

  fromHexString(hexString: string): Uint8Array {
    if (!hexString) return new Uint8Array();
    const reg = hexString.match(/.{1,2}/g);
    if (!reg) return new Uint8Array();
    return new Uint8Array(reg.map((byte) => parseInt(byte, 16)));
  }

  getStatus(expiry: number): string {
    const expsec = expiry * 1000;

    const now = new Date().getTime();
    if (expsec > now) return "OK";

    // Temporarily extend expiry to 2024-06-17
    // UTC: Jun 17 2024 00:00:00
    if (now < 1718582400000) return "Extended";
    if (now < expsec + 90 * 24 * 60 * 60 * 1000) return "Grace Period";
    return "Releasing";
  }
}
