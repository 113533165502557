
import { getWealthiest, RecentMinted, WealthyUser } from "@/service/ranking";
import { copy, unprefix0x } from "@/service/utility";
import { bech32m } from "@scure/base";
import { Vue } from "vue-class-component";

interface WealthyUserWrapper extends WealthyUser {
  showAllNames: boolean;
}
export default class Ranking extends Vue {
  public topHolders: WealthyUserWrapper[] = [];
  public lastMinted: RecentMinted[] = [];
  public currentTopHolderPage = 1;
  public pageSize = 10;

  get totalPage(): number {
    return this.topHolders.length / this.pageSize;
  }

  get currentHolders(): WealthyUserWrapper[] {
    return this.topHolders.slice((this.currentTopHolderPage - 1) * this.pageSize, this.currentTopHolderPage * this.pageSize);
  }

  mounted(): void {
    getWealthiest().then(
      (res) =>
        (this.topHolders = res.map((r) => ({
          balance: r.balance,
          count: r.count,
          names: r.names,
          puzzle_hash: r.puzzle_hash,
          showAllNames: false,
        })))
    );
    // getRecent().then((res) => (this.lastMinted = res));
  }

  getAddressFromPuzzleHash(hash: string): string {
    return bech32m.encode("xch", bech32m.toWords(this.fromHexString(unprefix0x(hash))));
  }

  fromHexString(hexString: string): Uint8Array {
    if (!hexString) return new Uint8Array();
    const match = hexString.match(/.{1,2}/g);
    if (!match) return new Uint8Array();
    return new Uint8Array(match.map((byte) => parseInt(byte, 16)));
  }

  shorten(address: string, ellipsis = "...", offset = 0, head = 7, tail = 4): string {
    if (!address) return "";
    if (address.length <= offset + head + tail + ellipsis.length) return address;
    return address.slice(offset, head) + ellipsis + address.slice(-tail);
  }

  copy(copyText: string): void {
    copy(copyText);
    this.$notify("Copied");
  }
}
